<template>
  <a-spin :spinning="spinShow">
    <a-form :form="form">
      <a-form-item label="主机(host)" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input
          v-decorator="[
            'host',
            { rules: [{ required: true, message: '主机不能为空' }], initialValue: '202.100.100.50:4443' },
          ]"
          placeholder="请输入主机地址"
        >
        </a-input>
      </a-form-item>
      <a-form-item label="秘钥(appKey)" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input
          v-decorator="['appKey', { rules: [{ required: true, message: '秘钥不能为空' }], initialValue: '27339437' }]"
          placeholder="请输入秘钥"
        >
        </a-input>
      </a-form-item>
      <a-form-item label="秘钥(appSecret)" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input
          v-decorator="[
            'appSecret',
            { rules: [{ required: true, message: '秘钥不能为空' }], initialValue: 'zATVBCzPOAAiHtnWBzAz' },
          ]"
          placeholder="请输入秘钥"
        >
        </a-input>
      </a-form-item>
      <a-form-item label="所属部门" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-tree-select
          v-decorator="['departmentId', { rules: [{ required: true, message: '部门不能为空' }], initialValue: orgId }]"
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          :treeData="orgTree"
          placeholder="请选择部门"
        ></a-tree-select>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
import { syncCameraFormVO } from './common/common'
import SERVICE_URLS from '@/api/service.url'

export default {
  name: 'Form',
  data() {
    return {
      labelCol: { span: 7 },
      wrapperCol: { span: 15 },
      orgTree: [],
      spinShow: false,
      formItem: syncCameraFormVO(),
      form: this.$form.createForm(this),
      orgId: null,
      showFormItem: false,
    }
  },
  created() {
    this.getOrgTree()
    this.defaultOrg()
  },
  mounted() {},
  methods: {
    defaultOrg() {
      this.$nextTick(() => {
        const organizationJobs = this.$store.getters.currentUser.organizationJobs
        organizationJobs.forEach((ele) => {
          if (ele.ifDefault) {
            this.orgId = ele.organizationId + ''
          }
        })
      })
    },
    getOrgTree() {
      this.$http(this, {
        url: SERVICE_URLS.organization.antTree,
        noTips: true,
        success: (data) => {
          this.orgTree = data.body
        },
      })
    },
    setVOFields(values) {
      Object.assign(this.formItem, values)
    },
    setFields(values) {
      Object.assign(this.formItem, values)
      this.form.setFieldsValue({
        host: this.formItem.host,
        appKey: this.formItem.appKey,
        departmentId: this.formItem.departmentId,
        appSecret: this.formItem.appSecret,
      })
    },
    submitAdd() {
      this.spinShow = true
      this.form.validateFields((err, values) => {
        if (!err) {
          this.setVOFields(values)
          this.$http(this, {
            url: SERVICE_URLS.hkInfo.synchronous_devices,
            data: this.formItem,
            successTipsContent: '同步设备信息成功!',
            success: (data) => {
              this.formItem = syncCameraFormVO()
              this.$emit('addSuccess')
              this.form.resetFields()
              this.spinShow = false
            },
            error: (data) => {
              this.$emit('addError')
              this.form.resetFields()
              this.spinShow = false
            },
          })
        } else {
          this.spinShow = false
        }
      })
    },
  },
}
</script>

<style scoped></style>
